<ion-content class="has-header" overflow-scroll="true">
  <ion-row style="position: relative; min-height: 100%">
    <ion-col style="margin-bottom: 80px" size="12" size-lg="10" offset-lg="1" size-xl="10" offset-xl="1">
      <app-header title="Administration" backTitle="Accueil" link="recherche"></app-header>

      <div class="content">
        <ion-row>
          <ion-col size="12">
            <h2 style="text-align: center;">Détails des utilisateurs</h2>
            <button mat-flat-button color="primary" (click)="downloadStats()" type="submit" style="
                                    min-height: 36px;
                                    border-radius: 0px;
                                    text-align: -webkit-center;
                                    display: block;
                                    margin-left: auto;
                                    margin-right: auto;
                                  ">
              <span *ngIf="!downloadLoading">Télécharger les statistiques</span>
              <mat-progress-spinner style="display: block; margin-left: auto; margin-right: auto;" *ngIf="downloadLoading" [diameter]="24" [mode]="'indeterminate'" color="accent">
              </mat-progress-spinner>
            </button>
            <br>
            <app-user-details></app-user-details>
          </ion-col>
        </ion-row>
        <hr>
        <ion-row>
          <ion-col size="12">
            <h2 style="text-align: center;">Créer un utilisateur</h2>
            <app-create-user [isLoading]="isCreateUserLoading" (onCreateUser)="createUser($event)"></app-create-user>
          </ion-col>
        </ion-row>
      </div>
    </ion-col>
    <app-footer></app-footer>
  </ion-row>
</ion-content>