<ion-progress-bar *ngIf="isPageLoading || isToggleUserPaymentStatusLoading" type="indeterminate"></ion-progress-bar>
<div *ngIf="!isPageLoading" class="container-fluid">
  <ion-row>

    <div class="dragndrop-container">
      <h2>Essais ({{trialUsers.length }})</h2>
      <div cdkDropList id="trial" #trialList="cdkDropList" [cdkDropListData]="trialUsers"
        [cdkDropListConnectedTo]="[trialExpiredList, suspendedList,normalList]" class="dragndrop-list"
        (cdkDropListDropped)="drop($event)">
        <div *ngFor="let u of trialUsers">
          <div class="dragndrop-box"
            [style.background-color]="(selectedUser != undefined && u.id==selectedUser.id) ? '#FD3F92':''" cdkDrag>
            {{u.firstname + ' ' + u.lastname }} ({{u.email }})
            <mat-icon mat-icon-button (click)="selectUser(u.id);">
              visibility
            </mat-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="dragndrop-container">
      <h2>Essais expirés ({{trialExpiredUsers.length }})</h2>
      <div cdkDropList id="trialExpired" #trialExpiredList="cdkDropList" [cdkDropListData]="trialExpiredUsers"
        [cdkDropListConnectedTo]="[trialList,suspendedList,normalList]" class="dragndrop-list"
        (cdkDropListDropped)="drop($event)">
        <div *ngFor="let u of trialExpiredUsers">
          <div class="dragndrop-box"
            [style.background-color]="(selectedUser != undefined && u.id==selectedUser.id) ? '#FD3F92':''" cdkDrag>
            {{u.firstname + ' ' + u.lastname }} ({{u.email }})
            <mat-icon mat-icon-button (click)="selectUser(u.id);" style="cursor: pointer;">
              visibility
            </mat-icon>
          </div>
        </div>
      </div>
    </div>

    <div class="dragndrop-container">
      <h2>Normaux ({{normalUsers.length }})</h2>
      <div cdkDropList id="normal" #normalList="cdkDropList" [cdkDropListData]="normalUsers"
        [cdkDropListConnectedTo]="[trialExpiredList,suspendedList,trialList]" class="dragndrop-list"
        (cdkDropListDropped)="drop($event)">
        <div *ngFor="let u of normalUsers">
          <div class="dragndrop-box"
            [style.background-color]="(selectedUser != undefined && u.id==selectedUser.id) ? '#FD3F92':''" cdkDrag>
            {{u.firstname + ' ' + u.lastname }} ({{u.email }})
            <mat-icon mat-icon-button (click)="selectUser(u.id);">
              visibility
            </mat-icon>
          </div>
        </div>
      </div>
    </div>

    <div class="dragndrop-container">
      <h2>Arrêtés/suspendus ({{suspendedUsers.length }})</h2>
      <div cdkDropList id="suspended" #suspendedList="cdkDropList" [cdkDropListData]="suspendedUsers"
        [cdkDropListConnectedTo]="[trialExpiredList,normalList,trialList]" class="dragndrop-list"
        (cdkDropListDropped)="drop($event)">
        <div *ngFor="let u of suspendedUsers">
          <div class="dragndrop-box"
            [style.background-color]="(selectedUser != undefined && u.id==selectedUser.id) ? '#FD3F92':''" cdkDrag>
            {{u.firstname + ' ' + u.lastname }} ({{u.email }})
            <mat-icon mat-icon-button (click)="selectUser(u.id);">
              visibility
            </mat-icon>
          </div>
        </div>
      </div>
    </div>
  </ion-row>
  <ion-row>
    <ion-col>
      <ion-progress-bar *ngIf="isUserLoading" type="indeterminate"></ion-progress-bar>
      <ion-row *ngIf="!selectedUser && !isUserLoading"
        style="height: 100%; display: flex; justify-content: center; align-items: center;">
        <p>Sélectionne un utiliseur, Nadège :-)</p>
      </ion-row>
      <ion-row *ngIf="selectedUser">
        <ion-col>
          <h2 style="margin-bottom: 0;">{{selectedUser.firstname + ' ' + selectedUser.lastname }}, {{
            selectedUser.etab }}
          </h2>
          <p style="margin-bottom: 15px;"><i>Créé le: {{ selectedUser.dateCreated | date: 'longDate'}}</i></p>

          <button *ngIf="!editPassword" mat-flat-button color="primary" (click)="editPassword=true;" style="
          min-height: 36px;
          border-radius: 0px;
          text-align: -webkit-center;
        ">
            <span>Changer le mot de passe</span>
          </button>
          <div *ngIf="editPassword">
            <mat-form-field appearance="outline" color="accent">
              <mat-label>Nouveau mot de passe</mat-label>
              <input matInput [(ngModel)]="newPassword" type="password" />
            </mat-form-field>
            <button mat-icon-button matSuffix (click)="editPassword=false">
              <mat-icon>clear</mat-icon>
            </button>
            <button mat-icon-button matSuffix (click)="changePassword()">
              <mat-icon *ngIf="!editPasswordLoading">check</mat-icon>
              <mat-progress-spinner *ngIf="editPasswordLoading" style="display: block;" [diameter]="24"
                [mode]="'indeterminate'" color="accent"></mat-progress-spinner>
            </button>
          </div>
          <div *ngIf="editEmail">
            <mat-form-field appearance="outline" color="accent">
              <mat-label>email</mat-label>
              <input matInput [(ngModel)]="newEmail" />
            </mat-form-field>
            <button mat-icon-button matSuffix (click)="editEmail=false">
              <mat-icon>clear</mat-icon>
            </button>
            <button mat-icon-button matSuffix (click)="changeEmail()">
              <mat-icon *ngIf="!isChangeEmailLoading">check</mat-icon>
              <mat-progress-spinner *ngIf="isChangeEmailLoading" style="display: block;" [diameter]="24"
                [mode]="'indeterminate'" color="accent"></mat-progress-spinner>
            </button>
          </div>
          <h3 *ngIf="!editEmail" style="margin-bottom: 0%;">{{ selectedUser.email }}
            <button mat-icon-button matSuffix (click)="editEmail= true; newEmail=selectedUser.email">
              <mat-icon>edit</mat-icon>
            </button>
          </h3>
          <div *ngIf="selectedUser.address">
            <h3 style="margin-bottom: 0%;">Adresse:</h3>
            <div *ngIf="editAddress">
              <form [formGroup]="editAddressForm" style="max-width: 480px;">
                <div style="display: flex; flex-direction: row;">
                  <mat-form-field appearance="outline" color="accent" style="width: 90%">
                    <mat-label>Rue: </mat-label>
                    <input id="street" matInput formControlName="street" />
                  </mat-form-field>
                  <br />
                  <mat-form-field appearance="outline" color="accent" style="width: 10%">
                    <mat-label>N°: </mat-label>
                    <input id="number" matInput formControlName="number" />
                  </mat-form-field>
                  <br />
                </div>
                <div style="display: flex; flex-direction: row;">
                  <mat-form-field appearance="outline" color="accent" style="width: 30%">
                    <mat-label>Code postal: </mat-label>
                    <input id="zip" matInput formControlName="zip" />
                  </mat-form-field>
                  <br />
                  <mat-form-field appearance="outline" color="accent" style="width: 70%">
                    <mat-label>Ville: </mat-label>
                    <input id="city" matInput formControlName="city" />
                  </mat-form-field>
                </div>
                <br />
                <button mat-flat-button color="primary" [disabled]="editAddressForm.invalid" (click)="changeAddress()"
                  type="submit" style="
                                    min-height: 36px;
                                    border-radius: 0px;
                                    text-align: -webkit-center;
                                    display: block;
                                    margin-left: auto;
                                    margin-right: auto;
                                  ">
                  <span *ngIf="!isChangeAddressLoading">Mettre à jour</span>
                  <mat-progress-spinner *ngIf="isChangeAddressLoading" [diameter]="24" [mode]="'indeterminate'"
                    style="display: block;" color="accent"></mat-progress-spinner>
                </button>
              </form>
            </div>
            <div *ngIf="!editAddress">
              <button mat-icon-button matSuffix (click)="editAddress= ! editAddress">
                <mat-icon>{{ editAddress? 'clear' : 'edit' }}</mat-icon>
              </button>
              <p>{{ selectedUser.address.street }} {{ selectedUser.address.number }}</p>
              <p>{{ selectedUser.address.zip}} {{ selectedUser.address.city }}</p>
            </div>
          </div>
          <br>
          <mat-checkbox class="d-block" [checked]="selectedUser.hasI18n"
            (change)="changeI18N(selectedUser.id,$event.checked)">
            <p *ngIf="!istoggleI18NLoading">Fonctionnalité multilingue?</p>
            <mat-progress-spinner style="display: block;" *ngIf="istoggleI18NLoading" [diameter]="24"
              [mode]="'indeterminate'" color="accent">
            </mat-progress-spinner>
          </mat-checkbox>
          <div *ngIf="userRecords">
            <h2>Supprimer un dossier: </h2>
            <mat-progress-spinner *ngIf="isDeleteRecordLoading || loadingRecords" [diameter]="24"
              [mode]="'indeterminate'" style="display: block;" color="accent"></mat-progress-spinner>
            <mat-form-field appearance="fill" *ngIf="userRecords.length > 0 && !isDeleteRecordLoading">
              <mat-label>choisir un numéro de dossier...</mat-label>
              <mat-select *ngIf="!loadingRecords" [multiple]="false" (selectionChange)="deleteRecord($event.value)">
                <mat-option *ngFor="let record of userRecords" [value]="record">
                  {{ record.recordNumber }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <p *ngIf="userRecords.length == 0">Aucun dossier.</p>
          </div>
        </ion-col>
      </ion-row>
    </ion-col>
  </ion-row>

</div>